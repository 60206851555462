import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../../redux';
import useProviderFilters from 'hooks/useProviderFilters';

import Input from 'components/Input';
import Paginator from 'components/Paginator';
import BetsFilter from './BetsFilter';
import BetTypesFilter from './BetTypesFilter';
import ProviderFilter from './ProviderFilter';
import Table from './Table';

import './Bets.scss';

const Bets = () => {
  const b = block('bets');
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { transfers, count } = useSelector(state => state.bets, shallowEqual);

  const [userId, changeUserId] = useState(0);
  const [page, changePage] = useState(1);
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const pagination = {
    offset: page - 1,
    limit: 10,
    pages() {
      return Math.ceil(count / this.limit);
    },
  };

  const { filters, setFilters } = useProviderFilters(locale);
  const betTableHeads = { ...locale.betTableHeads };

  const getTransfers = useCallback(() => {
    const { beginDate, endDate } = date;
    const format = 'YYYY-MM-DD';
    const [provider, gameType, status] = [
      filters.provider.value === 'all' ? null : filters.provider.value,
      filters.gameType.value === 'all' ? null : filters.gameType.value,
      filters.betType.value === 'all' ? null : filters.betType.value,
    ];
    const info = {
      userId: userId,
      dateFrom: dayjs(beginDate).format(format),
      dateTo: dayjs(endDate).format(format),
      provider,
      gameType,
      count: 10,
      page: pagination.offset,
      status,
    };
    const queryParams = [...Object.entries(info)].map(el => el.join('=')).join('&');
    dispatch(actions.getTransfers(queryParams));
  }, [date, page, filters, userId, transfers]);

  const onChangeBeginDate = value => {
    setDate({ ...date, beginDate: value });
  };
  const onChangeEndDate = value => {
    setDate({ ...date, endDate: value });
  };
  const onChangeUserId = e => {
    changeUserId(e.currentTarget.value);
  };

  useEffect(() => {
    !!transfers.length && getTransfers();
  }, [page]);

  useEffect(() => {
    page > 1 && !transfers.length && changePage(1);
  }, [transfers]);

  return (
    <div className={b()}>
      <div className={b('user-id-input')}>
        <p>{locale.userId}</p>
        <Input value={userId} onChange={onChangeUserId} type="number" />
      </div>
      <ProviderFilter filters={filters} locale={locale} onChange={filters => setFilters({ ...filters })} />
      <BetTypesFilter filters={filters} locale={locale} onChange={setFilters} />
      <BetsFilter
        locale={locale}
        beginDate={date.beginDate}
        endDate={date.endDate}
        onSubmit={getTransfers}
        onChangeBeginDate={onChangeBeginDate}
        onChangeEndDate={onChangeEndDate}
      />
      <div className={b('table')}>
        <Table locale={locale} heads={betTableHeads} items={transfers} />
      </div>
      <div className={b('pagination')}>
        <Paginator pages={pagination.pages()} currentPage={page} locale={locale} changePage={changePage} />
      </div>
    </div>
  );
};

export default Bets;
