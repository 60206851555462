import EnPNG from './img/en.png';
import FrPNG from './img/fr.png';
import ArPNG from './img/ar.png';


export const languages = {
  EN: 'en-US',
  FR: 'fr-fr',
  AR: 'ar-ar',
};

export const languagesWithIcons = {
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
  'fr-fr': { lang: languages.FR, icon: FrPNG, text: 'Française' },
  'ar-ar': { lang: languages.AR, icon: ArPNG, text: 'عربى' },
};

export const shortLanguages = {
  'en-US': 'en',
  'fr-fr': 'fr',
  'ar-ar': 'ar',
};

const rtlLangs = [languages.AR];

export const checkIsRtlLang = lang => {
  return rtlLangs.includes(lang);
}
