import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { languagesWithIcons } from 'services/locale';
import { actions as localeActions } from 'features/locale';
import BackBlock from 'components/BackBlock';
import Button from 'components/Button';

import './Locale.scss';

const b = block('locale');

const Locale = ({ callback = () => null }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const isAuth = useSelector(state => state.auth.isAuth);
  const history = useHistory();

  const [activeLang, changeActivelang] = useState(lang);

  const langItems = useMemo(
    () =>
      Object.entries(languagesWithIcons).map(temp => (
        <div
          key={temp[0]}
          className={b('item', { active: activeLang === temp[0] })}
          onClick={() => changeActivelang(temp[0])}>
          <img src={temp[1].icon} alt="" className={b('item-flag')} />
          <span className={b('item-text')}>{temp[1].text}</span>
        </div>
      )),
    [lang, dispatch, activeLang],
  );

  const handleButtonClick = () => {
    dispatch(localeActions.changeLang(activeLang));
    if (!isAuth) history.push('/');
  }

  return (
    <section className={b()}>
      {/* <div className={b('title')}>{locale.languageSelection}</div> */}
      <BackBlock>{<span className={b('back-block-text')}>{locale.changeLanguage}</span>}</BackBlock>
      {langItems}
      <div className={b('bottom')}>
        <div className={b('button')}>
          <Button onClick={handleButtonClick}>
            <span className={b('button-text')}>{locale.changeLang}</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Locale;
