import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import block from 'bem-cn';

import { actions } from 'features/users/redux';
import Input from 'components/Input';

import './ChangeChildPas.scss';

const b = block('change-child-pas');

const ChangeChildPas = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!params.id) {
      history.push('/');
    }
  }, [params, history]);

  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const [pas, setPas] = useState('');
  const [changedSuccess, setChangedSuccess] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(true);
  const [passwordError, setPasswordError] = useState(locale.errorPassword);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (passwordError) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [passwordError]);

  const blureHandler = e => {
    switch (e.target.name) {
      case 'password':
        setPasswordDirty(true);
        break;
      // eslint-disable-next-line no-unused-expressions
      default:
        () => {};
    }
  };

  const passwordHandler = e => {
    setPas(e.target.value);
    const re = /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{8,}/;
    if (!re.test(String(e.target.value))) {
      setPasswordError(locale.errorPassword);
      if (!e.target.value) {
        setPasswordError(locale.noPass);
      }
    } else {
      setPasswordError('');
    }
  };

  const onResetClick = useCallback(() => {
    dispatch(actions.resetPassword({ id: params.id, callBack: setChangedSuccess, newPassword: pas }));
  }, [dispatch, params.id, pas]);

  useEffect(() => {
    if (changedSuccess) {
      history.push(`/user-edit/${params.id}`);
    }
  }, [changedSuccess, history, params.id]);

  return (
    <div className={b()}>
      {passwordError && passwordDirty && <div className={b('error')}>{passwordError}</div>}
      <Input
        type="text"
        value={pas}
        callBack={e => setPas(e.currentTarget.value)}
        onChange={e => passwordHandler(e)}
        placeholder={locale.theNewPassword}
        name="password"
        onBlur={e => blureHandler(e)}
      />
      <button className={b('button')} disabled={!isValid} onClick={onResetClick}>
        {locale.changePas}
      </button>
    </div>
  );
};

export default ChangeChildPas;
