import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from 'features/Auth';
import Notify from 'features/notify/view/mobile';
import CreateUser from 'features/users/view/mobile/CreateUser';
import UsersList from 'features/users/view/mobile/UsersList';
import UserEdit from 'features/users/view/mobile/UserEdit';
import ChangePas from 'features/ChangePas/mobile';
import Total from 'features/Total/view/mobile';
import Tree from 'features/tree/view/mobile';
import Bets from 'features/bets/view/mobile';
import CashierJackpot from 'features/cashierJackpot/view/mobile';
import SlotsBetHistory from 'features/slotsBetHistory/view/mobile';
import TransactionHistory from 'features/transaction/view/mobile/TransactionHistory';
import ChangeChildPas from 'features/ChangeChildPas/mobile';

import Footer from 'components/Footer/mobile';
import Header from 'components/Header';
import AsyncPollBalance from 'components/AsyncPoll/AsyncPollBalance';

import Locale from 'features/locale/view/mobile';
import Auth from 'features/Auth/view/mobile';
import Menu from 'components/Menu';

import './App.scss';

const b = block('app');

const App = ({ location }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const user = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  const isLocale = location.pathname === '/locale';

  return (
    <div className={b()}>
      <Notify />
      <Header />
      {/* {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />} */}
      {isAuth ? (
        <Switch>
          <Route exact key="/user-create" path="/user-create" component={CreateUser} />
          <Route exact key="/users-list" path="/users-list" component={UsersList} />
          <Route exact key="/total" path="/total" component={Total} />
          {/* <Route exact key="/totalBalance" path="/totalBalance" component={TotalBalance} /> */}
          <Route exact key="/user-edit/:id" path="/user-edit/:id" component={UserEdit} />
          <Route exact key="/transaction-history" path="/transaction-history" component={TransactionHistory} />
          <Route exact key="/change-pas" path="/change-pas" component={ChangePas} />
          <Route exact key="/tree" path="/tree" component={Tree} />
          <Route exact key="/casino-history" path="/casino-history" component={SlotsBetHistory} />
          <Route exact key="/bets" path="/bets" component={Bets} />
          <Route exact key="/cashier-jackpot" path="/cashier-jackpot" component={CashierJackpot} />
          <Route exact key="/" path="/" component={Menu} />
          <Route exact key="/change-child-pas" path="/change-child-pas/:id" component={ChangeChildPas} />
        </Switch>
      ) : (
        !isLocale && <Auth />
      )}
      <Switch>
        <Route exact key="/locale" path="/locale" component={Locale} />
      </Switch>
      {isAuth && location.pathname === '/' && <Footer locale={locale} isAuth={isAuth} />}
      <AsyncPollBalance />
    </div>
  );
};

export default withRouter(App);
