import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from 'components/Input';
import BackBlock from 'components/BackBlock';
import Spinner from 'components/Spinner';
import Button from 'components/Button';

import { actions as usersActions } from 'features/users/redux';

import { validatePassword } from 'shared/helpers/validatePassword';

import { userPasswordValidatorSettings } from './data';

import './CreateUser.scss';

const b = block('create-user');

const CreateUser = ({ history }) => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, changePassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const role = useSelector(state => state.auth.role);
  const locale = useSelector(state => state.locale.locale);
  const actionProcessing = useSelector(state => state.users.actionProcessing);

  const disabled =
    actionProcessing || !(password.length && isPasswordValid) || !(userName.length /*  && isUsernameValid */);

  const onClick = useCallback(() => {
    if (!disabled) dispatch(usersActions.createUser(userName, password, userId => history.replace('/')));
  }, [disabled, dispatch, password, userName, history]);

  let headerText = locale[`newAgent${role}`];

  return (
    <div className={b()}>
      <div className={b('back-block')}>
        <BackBlock>{headerText}</BackBlock>
      </div>
      <div className={b('content')}>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.userName}:`}</div>
          <div className={b('field-input', { valid: !userName.length ? 1 : 2 /* isUsernameValid ? 2 : 3 */ })}>
            <Input
              placeholder={locale.userName}
              value={userName}
              onChange={e => changeUserName(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.password}:`}</div>
          <div className={b('field-input', { valid: !password.length ? 1 : isPasswordValid ? 2 : 3 })}>
            <Input
              placeholder={locale.password}
              value={password}
              onChange={e => {
                changePassword(e.currentTarget.value);
                setIsPasswordValid(validatePassword(e.currentTarget.value, userPasswordValidatorSettings));
              }}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button onClick={onClick} disabled={disabled}>
            <span className={b('button-text')}>{locale.createAccount}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateUser);
