/* eslint-disable func-names */
import React, { useState, useMemo } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';

import './Select.scss';

const b = block('select-mobile');

const Select = function ({ items, activeItem, onChange, disabled = false, placeholder = '' }) {
  const [isOpen, changeOpen] = useState(false);

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <li key={item.name} className={b('item')} onClick={() => !disabled && onChange(item.value)}>
          {item.icon && <img className={b('item-icon').toString()} src={item.icon} alt={item.name} />}
          <span className={b('item-value')}>{item.name}</span>
        </li>
      )),
    [items, disabled, onChange],
  );

  return (
    <div className={b({ open: isOpen })} onClick={() => changeOpen(!isOpen)}>
      <div className={b('item', { current: true })}>
        {activeItem.icon && (
          <img className={b('item-icon').toString()} src={activeItem.icon} alt={activeItem.name || placeholder} />
        )}
        <span className={b('item-value', { current: true })}>{activeItem.name || placeholder}</span>
        <SVG className={b('item-arrow').toString()} svgProps={{ svg: arrowSVG }} />
      </div>
      {isOpen && (
        <ul className={b('items')} onMouseLeave={() => changeOpen(false)}>
          {itemsList}
        </ul>
      )}
    </div>
  );
};

export default Select;
