import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Change language',
  changePas: 'Change pasword',
  logout: 'Logout',
  other: 'Other',
  successAuth: 'Success authorization',
  notRules: 'Insufficient login privileges',

  newPlayer: 'New player',
  userName: 'Username',
  password: 'Password',
  createAccount: 'Create account',
  /* '/user-create': 'Cash register - New agent', */
  '/user-create-1': 'Cash register - New player',
  '/user-create-2': 'Cash register - New agent',
  '/user-create-3': 'Cash register - New admin',
  '/total': 'Cash register - Cashier transactions',
  '/transaction-history': 'Cash register - Transactions history',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/user-edit': 'Cash register - User edit',
  '/change-pas': 'Cash register - Change password',
  '/cashier-jackpot': 'Cash register - Cashier jackpot',
  '/users-list': 'Cash register - User cards balances',
  '/totalBalance': 'Balance',
  '/tree': 'Tree',
  '/change-child-pas': 'Cash register - Change password',

  userCardBalances: 'Users cards balances',
  userCard: 'User card',
  accountBalance: 'Account balance',
  nickname: 'Nickname',
  id: 'Id',
  betsMenu: 'Bets',
  balance: 'Balance',
  ban: 'Block',
  banSuccess: 'Block success',
  unbanSuccess: 'Unblock success',
  unban: 'Unblock',
  resetPassword: 'Reset password',
  resetSuccess: 'Reset password success',
  networkError: 'Network error',
  createSuccess: 'Create success',
  topUp: 'Recharge',
  page: 'Page',
  withdrawal: 'Withdrawal',
  topUpSuccess: 'Recharge success',
  withdrawalSuccess: 'Withdrawal success',
  topUpWithdrawal: 'Recharge/Withdrawal',
  ok: 'Ok',
  theNewPassword: 'The new password',
  for: 'for',
  all: 'All',
  '/transaction-history': 'Partners - Transaction history',
  startDate: 'Start date',
  endingDate: 'Ending date',
  display: 'Display',
  email: 'E-mail',
  date: 'Date',
  amount: 'Amount',
  languageSelection: 'Language selection',
  login: 'Login',

  betTypes: {
    all: 'All types',
    bet: 'Bet',
    win: 'Win',
    returned: 'Returned',
    cashedOut: 'Cashed out',
    cashed_out: 'Cashed out',
    lost: 'Lost',
    rollback: 'Rollback',
    refund: 'Refund',
  },
  betType: 'Bet type',
  selectPeriod: 'Select period',
  from: 'From',
  to: 'To',
  apply: 'Apply',
  userId: 'User id:',
  betTableHeads: {
    id: 'Id',
    type: 'Type',
    fromUserId: 'From user id',
    toUserId: 'To user id',
    value: 'Value',
    currency: 'Currency',
    note: 'Note',
    createdAt: 'Created at',
    description: 'Description',
    balanceBefore: 'Balance before',
  },
  noBets: 'No bets',

  cashRegister: 'Cash register',
  createUser: {
    1: 'New player',
    2: 'New agent',
    3: 'New admin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
  },
  bets: {
    1: 'Bets',
    2: 'Bets',
    3: 'Bets',
  },
  partners: 'Partners',
  revenuBalances: 'Revenu balances',
  transactionHistory: {
    1: 'Transaction history',
    2: 'Transaction history',
    3: 'Transaction history',
  },
  cashierJackpot: {
    1: 'Cashier jackpot',
    2: 'Cashier jackpot',
    3: 'Cashier jackpot',
    7: 'Cashier jackpot',
  },
  totalBalance: {
    1: 'Balance',
    2: 'Balance',
    3: 'Balance',
    7: 'Balance',
  },
  tree: {
    1: 'Tree',
    2: 'Tree',
    3: 'Tree',
    7: 'Tree',
  },
  casinoHistory: {
    1: 'Casino history',
    2: 'Casino history',
    3: 'Casino history',
    7: 'Casino history',
  },
  casinoHistory2: 'Casino history',

  total: 'Total',
  term: 'Term',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  cashPayment: 'Cash payment',
  cashPayout: 'Cash payout',
  search: 'Search',
  errorPassword: 'Password must consist of: at least 8 characters, at least one number and one letter',
  noPass: 'Password must not be empty!',
  dataUpdate: 'Data is updated once every 12 hours.Therefore, the data for the current day may be incorrect.',
  treeMenu: 'Tree',
  changePassword: 'Change password',
  players: 'Players',
  subusers: 'Sub-users',

  jackpotWinnersList: 'Jackpot winners list',
  startAmount: 'Start amount',

  winnerID: 'Winner ID',
  winDate: 'Win date',
  amountOfTheWinningJackpot: 'Amount of the winning jackpot',

  totalWithdrawal: 'Total withdrawal',
  totalTopUp: 'Total deposit',

  provider: 'Provider',
  gameType: 'Game type',

  newAgent1: 'Cash register - New player',
  newAgent2: 'Cash register - New agent',
  newAgent3: 'Cash register - New admin',

  home: 'Home',
  changeLanguage: 'Change language',
  changePassword: 'Change password',
  changedPasswordDoNotMatch: 'Passwords do not match',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm New Password',
  enterOldPassword: 'Enter old password',
  enterNewPassword: 'Enter new password',
  repeatNewPassword: 'Repeat new password',
  save: 'Save',

  typeSomething: 'Type something',

  userEdit: 'Cash register - User edit',

  userId: 'User ID',
  showResults: 'Show results',
  bet: 'bet',
  outcome: 'Outcome',
  fail: 'fail',

  role: {
    0: 'Player',
    1: 'Cashier',
    2: 'Admin',
    3: 'Suadmin',
  },
  'The password can contain only letters and numbers': 'The password can contain only letters and numbers',
  error: 'Error',
  lost: 'Lost',

  wantLogout: 'Are you sure you want to logout?',
  yesLogout: 'Yes, Logout',
  noIStay: "No, I'm staying here",
  welcomeToPanel: 'Welcome to Admin Panel!',
  useLeftMenu: 'Use the left menu to manage users',
};
