/* import { IPasswordValidationSettings } from 'api/SettingsApi/types'; */

export const validatePassword = (
  value /* : string */,
  settings /* ?: IPasswordValidationSettings */,
) /* : boolean */ => {
  const regExp1 = new RegExp(`\\d`, 'ig');
  const test1 = value.match(regExp1) || [];
  const correctDigitsCount = test1.length >= (settings ? settings.digits.amount : 0);

  const regExp2 = new RegExp(`[a-z]`, 'ig');
  const test2 = value.match(regExp2) || [];
  const correctLettersCount = test2.length >= (settings ? settings.letters.amount : 0);

  const regExp3 = new RegExp(`[a-z]`, 'g');
  const test3 = value.match(regExp3) || [];
  const correctLowerCaseLettersCount = test3.length >= (settings ? settings.letters.lowercase : 0);

  const regExp4 = new RegExp(`[A-Z]`, 'g');
  const test4 = value.match(regExp4) || [];
  const correctUpperCaseLettersCount = test4.length >= (settings ? settings.letters.uppercase : 0);

  const regExp5 = new RegExp(`[^\\d^\\w]`, 'ig');
  const test5 = value.match(regExp5) || [];
  const correctSpecialCharactersCount = test5.length /* >= */ === (settings ? settings.special_characters.amount : 0);

  const correctCharactersCount =
    value.length >= (settings ? settings.min_length : 0) && value.length <= (settings ? settings.max_length : 0);

  /* console.log(
    '\ncorrectDigitsCount',
    correctDigitsCount,
    '\ncorrectLettersCount',
    correctLettersCount,
    '\ncorrectLowerCaseLettersCount',
    correctLowerCaseLettersCount,
    '\ncorrectUpperCaseLettersCount',
    correctUpperCaseLettersCount,
    '\ncorrectSpecialCharactersCount',
    correctSpecialCharactersCount,
    '\ncorrectCharactersCount',
    correctCharactersCount,
  ); */

  return (
    (correctDigitsCount &&
      correctLettersCount &&
      correctLowerCaseLettersCount &&
      correctUpperCaseLettersCount &&
      correctSpecialCharactersCount &&
      correctCharactersCount) ||
    (settings ? !settings?.active : false)
  );
};
