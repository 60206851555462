import dayjs from 'dayjs';
import { shortLanguages, checkIsRtlLang } from 'services/locale';

// импорт локализации dayjs для языков
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/ar';

const actionTypes = {
  CHANGE_LANG: 'locale/CHANGE_LANG',
};

function changeLang(lang) {
  return async dispatch => {
    const { locale } = await import(`services/locale/${lang}/index`); // code-spliting для словарей
    const htmlTag = document.querySelector('html');
    htmlTag.lang = shortLanguages[lang];
    if (checkIsRtlLang(lang)) {
      htmlTag.setAttribute('dir', 'rtl');
    } else {
      htmlTag.setAttribute('dir', 'ltr');
    }
    dayjs.locale(shortLanguages[lang]);
    dispatch({ type: actionTypes.CHANGE_LANG, payload: { locale, lang } });
  };
}

export {
  actionTypes,
  changeLang,
};